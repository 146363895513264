import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { QtySelector } from 'components';

import style from '../ProductComponents.module.scss';

const QuantityWithSelect = ({ quantity, setQuantity, unitName, setUnitName, setUnitId, isMobile, disabled, validator }) => {
  const [unit, setUnit] = useState(null);

  const rawUnits = useSelector((state) => state.supplierForm.units);
  const units = useMemo(() => rawUnits.map((item) => ({ ...item, label: item.unit_name, value: item.id })), [rawUnits]);

  useLayoutEffect(() => {
    if (!unit) {
      setUnit(units.find((unit) => unit.label === unitName));
    }
  }, [units]);

  useEffect(() => {
    if (unit) {
      setUnitName(unit.label);
      setUnitId(unit.id);
    }
  }, [unit]);

  return (
    <div className={classNames(style.wrapper, style.quantityWrapper)}>
      <QtySelector
        state={quantity}
        setState={setQuantity}
        hideArrows
        width={90}
        label={isMobile ? 'Ilość' : null}
        id={'offerQty'}
        disabled={disabled}
        validator={validator}
        rule={'required|only_positive'}
        unitSelect
        unitState={unit}
        setUnitState={setUnit}
        units={units}
      />
    </div>
  );
};

export default QuantityWithSelect;
