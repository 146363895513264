import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { Input, Select } from 'components';

import { availabilities, productTypes } from 'src/constants/enums';
import { availabilityOptions as avOptions } from 'src/constants/misc';

import style from '../ProductComponents.module.scss';

const getDefaultDate = (avData) => (avData ? moment(avData).format('YYYY-MM-DD') : null);
const getDefaultAv = (availability) => avOptions.find((opt) => opt.value === availability) || avOptions[0];

const Availability = (props) => {
  const {
    isMobile,
    disabled,
    validator,
    setAvailability,
    availability,
    type,
    setDeliveryDate: setDate,
    deliveryDate: date
  } = props;

  const [deliveryDate, setDeliveryDate] = useState(getDefaultDate(date));
  const [selectedAv, setSelectedAv] = useState(getDefaultAv(availability));

  const options = useMemo(
    () => (type !== productTypes.product ? avOptions.filter((av) => av.value !== availabilities.notAvailable) : avOptions),
    [type]
  );

  useEffect(() => {
    const valuesAreEqual = availability === selectedAv.value;

    if (selectedAv?.value && !valuesAreEqual && setAvailability) {
      setAvailability(selectedAv.value);
    }
  }, [selectedAv, availability]);

  useEffect(() => {
    const deliveryDatesAreEqual = moment(deliveryDate)?.toISOString() === moment(date).toISOString();

    if (deliveryDate && !deliveryDatesAreEqual && setDate) {
      setDate(moment(deliveryDate).toISOString());
    }
  }, [deliveryDate, date]);

  return (
    <div className={classNames(style.wrapper, style.availabilitySelectWrapper)}>
      <div
        className={style.column}
        id={'availability-select'}
      >
        <Select
          options={options}
          value={selectedAv}
          onChange={setSelectedAv}
          placeholder={'Dostępność'}
          label={isMobile ? 'Dostępność' : null}
          isDisabled={disabled}
          isSearchable={false}
        />
        {selectedAv?.value === availabilities.availableSince && (
          <Input
            type='date'
            id='deliveryDate'
            name='deliveryDate'
            value={deliveryDate}
            onChange={(e) => setDeliveryDate(e.target.value)}
            validator={validator}
            rule={'required'}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default Availability;
